import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";
import { images } from "../Utility/Images";

function Footer() {
  return (
    <>
      <section className="py-4 news-letter">
        <div className="container">
          <div className="row align-items-end gx-lg-5 justify-content-center">
            <div className="col-12 col-md-4">
              <h1 className="heading mb-0 text-end brown">Newsletter</h1>
            </div>
            <div className="col-12 col-md-8">
              <form action="/" className="form row">
                <div className="col-12">
                  <div className="d-flex gap-3">
                    <input
                      type="email"
                      className="form-control bg-white"
                      placeholder="Subscribe to Our Weekly Newsletter"
                    />
                    <button className="btn btn-custom btn-brown">
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* ============================================================================= */}
      <footer>
        <div className="footer">
          <div className="container">
            <div className="row gx-lg-5">
              <div className="col-12 col-md-4">
                <div className="footer-inner">
                  <Link to="/" className="navbar-brand">
                    <img src={images.logo} className="main-logo mb-3" alt="" />
                  </Link>
                  <p className="text fs-15">
                    Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel
                    utom diska. Jinesade bel när feras redorade i belogi. FAR
                    paratyp i muvåning, och pesask vyfisat. Viktiga poddradio
                    har un mad och inde.
                  </p>
                 
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="footer-inner">
                  <h4 className="title">Quick Links</h4>
                  <ul className="links">
                    <li>
                      <Link to="/">Home it work</Link>
                    </li>
                    <li>
                      <Link to="/Shop">Shopping</Link>
                    </li>
                    <li>
                      <Link to="/">Sell</Link>
                    </li>
                    <li>
                      <Link to="/">Messages</Link>
                    </li>
                    <li>
                      <Link to="/">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/">Terms & Condition</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="footer-inner">
                  <h4 className="title">Catagories</h4>
                  <ul className="links">
                    <li>
                      <Link to="/">Plywood & MDF</Link>
                    </li>
                    <li>
                      <Link to="/">Laminates/Veneers</Link>
                    </li>
                    <li>
                      <Link to="/">Hardware/Furniture Fixtures</Link>
                    </li>
                    <li>
                      <Link to="/">Surface Designer Sheets & Panels</Link>
                    </li>
                    <li>
                      <Link to="/">Adhesive</Link>
                    </li>
                    <li>
                      <Link to="/">Wood</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="footer-inner">
                  <h4 className="title">Get In Touch</h4>
                  <ul className="links">
                    <li>
                      <div className="icon">
                        <FaPhoneAlt />
                      </div>
                      <a href="tel:+91 879 5465 845">+91 879 5465 845</a>
                    </li>
                    <li>
                      <div className="icon">
                        <GrMail />
                      </div>
                      <a href="mailto:info@plywoodbazar.com">
                        info@plywoodbazar.com
                      </a>
                    </li>
                    <li>
                      <div className="icon">
                        <ImLocation />
                      </div>
                      <a
                        href="https://goo.gl/maps/p98VKRVS2LHrf7LX6"
                        target="_blank"
                      >
                       248 Delhi, New Dellhi 110087
                      </a>
                    </li>
                  </ul>
                  <ul className="social-links">
                    <li>
                      <a href="#" className="icon">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icon">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icon">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icon">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright text-center">
          <div className="container">
            <p>
              © 2022, Plywood Bazar. Design & Developed by
              <a href="ebslon.com"> Ebslon Infotech</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
