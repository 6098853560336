import React from "react";

function PageBanner({ img, title, desp, className }) {
  return (
    <section
      className={`page-banner ${className ? className : ""}`}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="content">
              {title && <h1 className="heading">{title}</h1>}
              {desp && <p className="desp">{desp}</p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageBanner;
