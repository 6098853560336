import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiFillHome, AiOutlineLogout } from "react-icons/ai";
import { BiChevronDown, BiSearch } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import { GrProductHunt } from "react-icons/gr";
import { MdLocalOffer, MdOutlineSubscriptions, MdSportsEsports, MdSubscriptions } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, redirect, useNavigate } from "react-router-dom";
import { login, logoutUser, otpSend } from "../../redux/features/auth/authSlice";
import { getDecodedToken, getToken } from "../../services/auth.service";
import { getNestedCategories } from "../../services/Category.service";
import { images } from "../Utility/Images";
import { errorToast } from "../Utility/Toast";
import { debounce } from "lodash";
import { toastError } from "../../utils/toastutill";
import { searchProduct } from "../../services/Product.service";
import { BiWindowClose } from "react-icons/bi";
import { searchVendorFromDb } from "../../services/User.service";

function Header() {
  const dispatch = useDispatch();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const userObj = useSelector((state) => state.auth.user);
  const tempLoginObj = useSelector((state) => state.auth.tempLoginObj);
  const [searchBy, setSearchBy] = useState(false);
  const [searchVendor, setSearchVendor] = useState(true);
  const [searchList, setSearchList] = useState([{ name: "Search By Vendor", checked: true, vendor: true }, { name: "Search By Product", checked: false, vendor: false }]);
  const [searchText, setSearchText] = useState("");
  const [signInList, setSignInList] = useState([
    {
      name: "Profile",
      icon: <AiFillHome />,
      link: "/My-Profile",
    },
    // {
    //   name: "Post Your Requiement",
    //   icon: <GiPostOffice />,
    //   link: "/",
    // },
    {
      name: "My Products",
      icon: <GrProductHunt />,
      link: "/MyProducts",
    },
    // {
    //   name: "Verified Business Buyer",
    //   icon: <FaUserCheck />,
    //   link: "/",
    // },
    // {
    //   name: "Products/Services Directory",
    //   icon: <MdOutlineDocumentScanner />,
    //   link: "/",
    // },
    // {
    //   name: "My Orders",
    //   icon: <AiFillGift />,
    //   link: "/",
    // },
    {
      name: "My Leads",
      icon: <FaUserCheck />,
      link: "/MyLeads",
    },
    {
      name: "My Subscriptions",
      icon: <MdOutlineSubscriptions />,
      link: "/MySubscriptions",
    },
    {
      name: "Subscription",
      icon: <MdSubscriptions />,
      link: "/Subscription",
    },
    {
      name: "Recent Activity",
      icon: <MdSportsEsports />,
      link: "/Recent-Activities",
    },
    {
      name: "My Flash-Sales",
      icon: <MdLocalOffer />,
      link: "/View/My-FlashSales",
    },
    {
      name: "My Promotions",
      icon: <MdLocalOffer />,
      link: "/View/My-Promotions",
    },
    // {
    //   name: "Settings",
    //   icon: <AiTwotoneSetting />,
    //   link: "/",
    // },
  ]);
  const [showSignIn, setShowSignIn] = useState(false);
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [searchResultArr, setSearchResultArr] = useState([]);

  const [otpsent, setotpsent] = useState(false);
  const [signInModal, setSignInModal] = useState(false);
  const [mobile, setmobile] = useState("");
  const [otp, setotp] = useState("");
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([
    {
      img: images.country_1,
      prefix: "+91",
      active: true,
    },
    {
      img: images.country_2,
      prefix: "+92",
      active: false,
    },
    {
      img: images.country_3,
      prefix: "+93",
      active: false,
    },
  ]);
  const [showCountry, setShowCountry] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    img: countryList[0].img,
    prefix: countryList[0].prefix,
  });
  const [countryTimeout, setCountryTimeout] = useState(null);
  const [categoryArr, setcategoryArr] = useState([]);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [name, setname] = useState();

  const handleNestedcategories = async () => {
    try {
      let { data: res } = await getNestedCategories();
      if (res.data) {
        setcategoryArr(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRegister = () => {
    setSignInModal(false);
    return redirect("/Register");
  };

  const handleGetUser = async () => {
    let decodedToken = await getDecodedToken();
    let user = decodedToken?.userData?.user;
    if (user) {
      setname(user.name);
    }
  };

  useEffect(() => {
    setSignInModal(false);
    handleNestedcategories();
    if (getToken()) {
      handleGetUser();
    }
  }, [isAuthorized]);

  const handlesendOtp = async () => {
    // try {

    if (`${mobile}` === "") {
      errorToast("Please Enter Mobile Number");
      return;
    }
    let obj = {
      phone: mobile,
    };

    dispatch(otpSend(obj));

    // let { data: res } = await sentOtp(obj);
    // if (res.message) {
    //   successToast(res.message)
    //   setotpsent(true)
    // }
    // } catch (error) {
    //   errorToast(error)
    //   console.log(error)
    // }
  };

  useEffect(() => {
    if (tempLoginObj) {
      if (tempLoginObj && tempLoginObj.isOtpSent) {
        setotpsent(true);
      }
    }
  }, [tempLoginObj]);

  const handlesLogin = async () => {
    if (`${otp}` === "") {
      errorToast("Please Enter Otp");
      return;
    }
    let obj = {
      phone: mobile,
      otp,
    };
    dispatch(login(obj));
    setSignInModal(false);
  };

  const handleLogout = () => {
    // deleteToken()
    dispatch(logoutUser());
    setotp("");
    setmobile("");
    setotpsent(false);

    // setIsAuthorized(false)
  };

  const handleSearchText = (value) => {
    setSearchText(value);
    debounceSearch(value);
  };
  const debounceSearch = useCallback(
    debounce((nextVal) => handleSearchFromDb(nextVal), 1000),
    [searchVendor]
  );

  const handleSearchFromDb = async (value) => {
    try {
      console.log(searchVendor, "handleSearchText")

      if (value != "") {

        if (searchVendor) {
          const { data: res } = await searchVendorFromDb(`search=${value}`);
          if (res) {
            setSearchResultArr(res.data);
          }
          else {
            setDisplaySearchResults([]);
          }
        }
        else {
          const { data: res } = await searchProduct(value);
          if (res) {
            setSearchResultArr(res.data);
          }
          else {
            setDisplaySearchResults([]);
          }
        }


      }
    } catch (error) {
      toastError(error);
    }
  };



  const checkSearchMode = () => {
    return searchList.find((el) => el.checked)?.name;
  }




  useEffect(() => {
    if (searchList.some(el => el.checked)) {
      console.log(checkSearchMode())
    }
  }, [showSearchBar, searchList])

  const handleCheckItem = (obj) => {
    let tempArr = searchList.map(el => {
      if (el.name == obj.name) {
        el.checked = true
      }
      else {
        el.checked = false
      }

      return el
    })
    setSearchVendor(obj.vendor)
    setSearchList([...tempArr])
    setSearchResultArr([])
  }

  useEffect(() => {
    console.log(searchVendor, "searchVendor ")
  }, [searchVendor])

  return (
    <>
      <header style={{ display: "unset" }}>
        <div className="topbar bg-dark d-none">
          <Container fluid>
            <ul>
              <li>
                <p>
                  Call us toll free:&nbsp;
                  <a href="tel:+1-541-754-3010">+1-541-754-3010</a>
                </p>
              </li>
              <li>
                <p>|</p>
              </li>
              <li>
                <p>
                  Send us an email:&nbsp;
                  <a href="mailto:contact@example.com">contact@example.com</a>
                </p>
              </li>
            </ul>
          </Container>
        </div>
        <Navbar bg="white" className="middle-bar" expand="lg">
          <Container fluid>
            <Link to="/" className="navbar-brand">
              <img src={images.logo} alt="" className="main-logo" />
            </Link>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
            <Navbar.Offcanvas id="offcanvasNavbar-expand-lg" aria-labelledby="offcanvasNavbarLabel-expand-lg" placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">Offcanvas</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <form className="search-bar" onFocus={() => setShowSearchBar(true)}>
                  <div
                    className="custom-search"
                    onFocus={() => {
                      setSearchBy(!searchBy);
                    }}
                    onBlur={() => setSearchBy(!searchBy)}
                  >
                    <button
                      className="yellow-bg btn text-white h-100 rounded-0"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Select Search By
                      <BiChevronDown />
                    </button>
                    <ul className={`custom-search-list ${searchBy ? "show" : ""}`}>
                      {searchList.map((item, i) => {
                        return (
                          <li key={i} onClick={() => { handleCheckItem(item) }}>
                            <label className="pointer">
                              <input type="radio" checked={item.checked} className="form-check-input" name="header-search-by" />
                              {item.name}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="form-control flex-1">
                    <input type="search" placeholder={checkSearchMode()} aria-label="Search" value={searchText} onChange={(e) => handleSearchText(e.target.value)} />
                    <div className="icon">
                      <BiSearch />
                    </div>
                  </div>
                  {(searchText != "" && showSearchBar) && (
                    <div className="searchBox">
                      <div className="searchBoxCloseIcon" onClick={() => { setShowSearchBar(false) }}>
                        X
                      </div>
                      {searchResultArr && searchResultArr.length > 0 ? searchResultArr.map((el) => {
                        return (
                          <div>
                            {
                              searchVendor ?
                                <Link to={`/Supplier/${el?._id}`} onClick={() => setShowSearchBar(false)} onFocus={() => setShowSearchBar(true)}>
                                  <p>{el?.companyObj?.name}</p>
                                </Link>
                                :
                                <Link to={`/ShopDetail/${el?.slug}`} onClick={() => setShowSearchBar(false)} onFocus={() => setShowSearchBar(true)}>
                                  <p>{el.name}</p>
                                </Link>
                            }
                          </div>
                        );
                      })
                        :
                        <div>
                          {/* <Link to={`/ShopDetail/${el?.slug}`} onFocus={() => setShowSearchBar(true)}> */}
                          <p>No results found</p>
                          {/* </Link> */}
                        </div>
                      }
                    </div>
                  )}
                </form>
                <Nav>
                  <NavLink className="nav-link" to="/Shop">
                    Shopping
                  </NavLink>
                  <NavLink className="nav-link" to="/MyProducts">
                    Sell
                  </NavLink>
                  <NavLink className="nav-link" to="/View/My-Tickets">
                    Help
                  </NavLink>
                  <NavLink className="nav-link" to="/View/blogs">
                    Blogs
                  </NavLink>
                </Nav>
                {isAuthorized ? (
                  <div className="sign-in-btn">
                    <div className="custom-search" onFocus={() => setShowSignIn(!showSignIn)} onBlur={() => setShowSignIn(!showSignIn)}>
                      <button
                        className="text-dark d-flex align-items-center"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <img src={images.user} alt="" /> <BiChevronDown />
                      </button>
                      <ul className={`custom-search-list ${showSignIn ? "show" : ""}`}>
                        {signInList.map((item, i) => {
                          return (
                            <li key={i}>
                              <Link to={item.link}>
                                <div className="icon">{item.icon}</div>
                                {item.name}
                              </Link>
                            </li>
                          );
                        })}

                        <li onClick={() => handleLogout()}>
                          <a style={{ cursor: "pointer" }}>
                            <div className="icon">
                              <AiOutlineLogout />
                            </div>
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="sign-in-btn">
                    <div className="custom-search" onFocus={() => setShowSignIn(!showSignIn)} onBlur={() => setShowSignIn(!showSignIn)}>
                      <button
                        className="text-dark d-flex align-items-center"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <img src={images.user} alt="" /> Sign In <BiChevronDown />
                      </button>
                      <ul className={`custom-search-list ${showSignIn ? "show" : ""}`}>
                        <li className="text-center bottom-li">
                          <button className="yellow-bg btn text-white" onClick={() => setSignInModal(true)}>
                            Sign In
                          </button>
                          <p className="text text-dark">
                            New to Plywood Bazar?
                            <Link to="/Register" className="brown fw-semibold">
                              Join Now
                            </Link>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>


        <Navbar className="bottom-bar sticky-top" expand="lg">
          <Container fluid>
            <Navbar.Toggle aria-controls="bottomNavbar-expand-lg" />
            <Navbar.Offcanvas id="bottomNavbar-expand-lg" aria-labelledby="bottomNavbarLabel-expand-lg" placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="bottomNavbarLabel-expand-lg">Offcanvas</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="flex-grow-1">
                  {categoryArr &&
                    categoryArr.map((category, index) => (
                      <div className="nav-link">
                        <Link to={`/Shop?categories=${category._id}`}>{category?.name}</Link>
                        {category.subCategoryArr && category.subCategoryArr.length > 0 && (
                          <div className="custom-dropdown" key={index}>
                            <div className="container h-100">
                              <div className="custom-dropdown-inner">
                                <ul className="category-list">
                                  {category.subCategoryArr &&
                                    category.subCategoryArr.map((subcategory, index2) => (
                                      <li key={index2}>
                                        <Link to={`/Shop?categories=${category._id}&sub=${subcategory.slug}`} className="link">
                                          {subcategory.name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </header >

      <Modal show={signInModal} centered onHide={() => setSignInModal(false)}>
        <Modal.Body className="sign-in-modal custom-modal">
          <button type="button" class="btn-close" aria-label="Close" onClick={() => setSignInModal(false)}></button>
          <div>
            <Link to="/" className="navbar-brand">
              <img src={images.logo} alt="" className="main-logo" />
            </Link>
          </div>
          <h2 className="heading">Sign In</h2>
          <form className="form row">
            <div className="col-12">
              {otpsent ? (
                <div className="input flex-1">
                  <label className="text-start">Enter OTp sent to {mobile}</label>
                  <input type="text" className="w-100 form-control bg-grey" placeholder="Enter Your OTP" value={otp} onChange={(e) => setotp(e.target.value)} />
                </div>
              ) : (
                <div className="input flex-1">
                  <label className="text-start">Phone number</label>
                  <input type="text" className="w-100 form-control bg-grey" placeholder="Enter Your Mobile Number" value={mobile} onChange={(e) => setmobile(e.target.value)} />
                </div>
              )}
            </div>

            <div className="col-12">
              {otpsent ? (
                <button
                  type="button"
                  onClick={() => {
                    handlesLogin();
                  }}
                  className="btn btn-custom text-white yellow-bg py-2 w-100"
                >
                  Verfiy
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    handlesendOtp();
                  }}
                  className="btn btn-custom text-white yellow-bg py-2 w-100"
                >
                  Submit
                </button>
              )}

              <Link
                to="/Register"
                onClick={() => {
                  handleRegister();
                }}
                className="btn btn-custom mt-2 text-white yellow-bg py-2 w-100"
              >
                Register Now
              </Link>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
