import React, { createContext, useState } from "react";
import axios from "axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './assets/scss/main.css';
import RootRoute from "./router/RootRoute";

import toast, { Toaster } from 'react-hot-toast';

export let AuthoriseContext = createContext();
export let LoggedInUserContext = createContext();

export const axiosApiInstance = axios.create();

function App() {

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  return (
    <AuthoriseContext.Provider value={[isAuthorized, setIsAuthorized]}>
      <LoggedInUserContext.Provider value={[loggedInUser, setLoggedInUser]}>
        <Toaster />
        <BrowserRouter>
          <RootRoute />
        </BrowserRouter>
      </LoggedInUserContext.Provider>
    </AuthoriseContext.Provider>
  );
}

export default App;
