import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

let serverUrl = `${url}/subscription`;


export const getAllsubscription = async () => {
    return axiosApiInstance.get(`${serverUrl}/?perPage=${1500}`)
}



