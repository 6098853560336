import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getBrandApi } from "../../services/brand.service";
import { getAllCategories } from "../../services/Category.service";
import { createFlashSales, getFlashSalebyId, updateFlashSalebyId } from "../../services/FlashSales.service";
import { AddProduct, getAllProducts, getProductById, updateProductApi } from "../../services/Product.service";
import { generateImageUrl } from "../../services/url.service";
import { toastError } from "../../utils/toastutill";
import FileUpload from "../Utility/FileUpload";
import { errorToast, successToast } from "../Utility/Toast";

export default function AddFlashSale() {
    const navigate = useNavigate()
    let userObj = useSelector(state => state.auth.user)
    const [isEditingModeOn, setIsEditingModeOn] = useState(false);
    const [productArr, setProductArr] = useState([]);
    const [productId, setProductId] = useState("");
    const [price, setprice] = useState("")
    const [salePrice, setSalePrice] = useState("")
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [updateObj, setupdateObj] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const [flashSaleId, setFlashSaleId] = useState("");
    const [discountType, setDiscountType] = useState("Percentage");
    const [discountValue, setDiscountValue] = useState(0);
    const percentage = (percent, total) => {
        return ((percent / 100) * total)
    }

    const onSubmit = async () => {
        try {
            if (`${productId}` === '' || !productId) {
                errorToast('Please select a product')
                return 0
            }
            if (`${salePrice}` === '' || parseInt(salePrice) < 0) {
                errorToast('Please Fill Sale Price')
                return 0
            }
            if (`${price}` === '' || parseInt(price) < 0) {
                errorToast('Please Fill Price')
                return 0
            }
            if (discountType == "Percentage" && discountValue > 100) {
                errorToast('Percentage discount cannot be more than 100%')
                return 0
            }
            if (discountType == "Amount" && discountValue >= price) {
                errorToast('Amount discount cannot be more than price of the product')
                return 0
            }

            let obj = {
                userId: userObj._id,
                productId,
                price: price,
                discountType,
                discountValue,
                salePrice: (discountType == "Percentage" ? percentage(discountValue, price) : price - discountValue).toFixed(2),
                endDate,
                startDate
            }

            console.log(obj, "v")
            if (isEditingModeOn) {
                let { data: res } = await updateFlashSalebyId(searchParams.get("id"), obj)
                if (res.message) {
                    successToast(res.message)
                    navigate(-1)
                }
            }
            else {

                let { data: res } = await createFlashSales(obj)
                console.log(res, "asd")
                if (res.message) {
                    successToast(res.message)
                }
            }

        }
        catch (err) {
            errorToast(err)
        }

    }



    const handleGetProducts = async () => {
        try {

            let query = `page=${1}&perPage=${10000}&userId=${userObj?._id}`
            let { data: res } = await getAllProducts(query)
            if (res.data) {
                setProductArr(res.data)
            }
        }
        catch (err) {
            errorToast(err)
        }
    }


    const handleGetSaleById = async () => {
        try {

            let { data: res } = await getFlashSalebyId(searchParams.get("id"))
            if (res.data) {
                console.log(res.data, "getById")
                setProductId(res.data.productId);
                setDiscountType(res.data.discountType);
                setDiscountValue(res.data.discountValue);
                setprice(res.data.price)
                setSalePrice(res.data.salePrice)
                setStartDate(res.data.startDate)
                setEndDate(res.data.endDate)
                // setProductArr(res.data)
            }
        }
        catch (err) {
            errorToast(err)
        }
    }


    const handleDisountValueChange = (value) => {
        console.log((discountType == "Percentage" && value < 100),
            (discountType != "Percentage" && value < price), value, price)
        if (parseInt(value) >= 0) {
            let tempValue = 0
            if (discountType == "Percentage" && value <= 100) {
                tempValue = parseInt(price) - percentage(parseInt(value), parseInt(price))
                setDiscountValue(value)
            }
            else if (discountType != "Percentage" && parseInt(value) < parseInt(price)) {
                tempValue = parseInt(price) - parseInt(value)
                setDiscountValue(value)
            }
            setSalePrice(tempValue)
        }
        else {
            toastError("Discount cannot be less than 0")
            return
        }
    }


    useEffect(() => {
        handleGetProducts()
    }, [])



    useEffect(() => {
        if (searchParams.get("id")) {
            setIsEditingModeOn(true)
            handleGetSaleById()
        }
    }, [searchParams.get("id")])

    return (
        <div className="container">
            <div className="row m-3 pt-3">
                <div className="col-12 col-md-12">
                    <div className="right">
                        <h3 className="heading ps-3">{isEditingModeOn ? "Edit" : "Add"} Flash sale</h3>
                        <form className="form row profile-section-container " style={{ width: "100%" }}>
                            <h4 style={{ color: "rgba(0,0,0,0.7)" }}>Product Details </h4>

                            <div className="col-md-6">
                                <label>Product <span className="text-danger">*</span></label>

                                <select className='form-control' value={productId} onChange={(e) => {
                                    setProductId(e.target.value)
                                    let tempObj = productArr.find(el => el._id == e.target.value)
                                    setprice(`${tempObj.sellingprice}`);
                                    setDiscountValue(0); setSalePrice(0)
                                }}>
                                    <option value="">Please Select Product</option>
                                    {productArr && productArr.length > 0 && productArr.map(el => <option key={el._id} value={`${el._id}`}>{el.name}</option>)}
                                </select>
                            </div>

                            <div className="col-md-6">
                                <label>Discount type <span className="text-danger">*</span></label>
                                <select onChange={(e) => { setDiscountType(e.target.value); setDiscountValue(0); setSalePrice(0) }} value={discountType} className="form-control">
                                    <option value={"Percentage"}>Percentage</option>
                                    <option value={"Amount"}>Amount</option>
                                </select>
                            </div>

                            <div className="col-md-6">
                                <label>Discount Value <span className="text-danger">*</span></label>
                                <input onChange={(e) => { handleDisountValueChange(e.target.value) }} value={discountValue} type={"number"} className="form-control" />

                            </div>

                            <div className="col-md-6">
                                <label>Price <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={`${price}`}
                                    // disabled
                                    onChange={(e) => setprice(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Sale Price <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={`${salePrice && salePrice.toFixed(2)}`}
                                    // disabled
                                    onChange={(e) => setSalePrice(e.target.value)}
                                />
                            </div>

                            <div className="col-md-6">
                                <label>Start Date <span className="text-danger">*</span></label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    value={moment(startDate).format("YYYY-MM-DDThh:mm:ss")}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>End Date <span className="text-danger">*</span></label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    value={moment(endDate).format("YYYY-MM-DDThh:mm:ss")}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>


                            <div className="col-md-12">
                                <button type="button" onClick={() => { onSubmit() }} className="btn btn-custom btn-yellow mt-2">
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
