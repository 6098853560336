// local url
// export const url = "http://localhost:3016";
// export const adminUrl = "http://localhost:3001";

// production url
export const url = "/api";
export const adminUrl = "http://admin.plywood.ebslon.com";

export const generateImageUrl = (path) => {
  return `${url}/uploads/${path}`;
};
