import React, { useEffect, useState } from "react";
import { AiFillHome, AiOutlineWhatsApp } from "react-icons/ai";
import { BiHeadphone } from "react-icons/bi";
import { BsCalendarWeek, BsFillCheckCircleFill } from "react-icons/bs";
import { FaPhoneAlt, FaProductHunt, FaUserFriends } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImLocation, ImOffice, ImUser } from "react-icons/im";
import { images } from "../Utility/Images";
import PageBanner from "../Utility/PageBanner";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MdAddBusiness, MdCall, MdImportExport } from "react-icons/md";
import { GiChart, GiScales } from "react-icons/gi";
import Category from "../Category";
import { toastError } from "../../utils/toastutill";
import { getUserById } from "../../services/User.service";
import { generateImageUrl } from "../../services/url.service";
import { getAllProductsBySupplierId } from "../../services/Product.service";

function Supplier() {
  const [quoteModal, setQuoteModal] = useState(false);

  const params = useParams()
  const [supplierObj, setSupplierObj] = useState({});
  const [productsArr, setProductsArr] = useState([]);
  const [tabs, setTabs] = useState([
    {
      name: "Home",
      icon: <AiFillHome />,
      active: true,
      tab: "1",
    },
    {
      name: "Profile",
      icon: <ImUser />,
      active: false,
      tab: "2",
    },
    {
      name: "Our Products",
      icon: <FaProductHunt />,
      active: false,
      tab: "3",
    },
    // {
    //   name: "Help & Support",
    //   icon: <BiHeadphone />,
    //   active: false,
    //   tab: "4",
    // },
  ]);

  const ActiveTab = (i) => {
    const temp = tabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setTabs([...temp]);
  };



  const HandleGetProductBySupplierId = async (id) => {
    try {
      let { data: res } = await getAllProductsBySupplierId(id)
      if (res.data) {
        setProductsArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const HandleGetUserById = async (id) => {
    try {
      let { data: res } = await getUserById(id)
      if (res.data) {
        setSupplierObj(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  useEffect(() => {
    HandleGetUserById(params.id)
    HandleGetProductBySupplierId(params.id)
  }, [params])



  return (
    <main>
      <PageBanner img={images.top_banner} className="mt-4 mb-5" />

      <section className="supplier-detail mb-80">
        <div className="container">
          <ul className="box">
            <li className="left">
              <ul className="inner">
                <li>
                  <div className="image">
                    <img
                      src={images.category_6}
                      alt=""
                      className="img-thumbnail"
                    />
                  </div>
                </li>
                <li>
                  <ul>
                    <li>
                      <div className="icon brown">
                        <ImOffice />
                      </div>
                      {supplierObj.companyObj?.name}
                    </li>
                    <li>
                      <a target="_blank" href={supplierObj.companyObj?.googleMapsLink} style={{ display: "flex", flexDirection: "row" }}>
                        <div className="icon brown pe-2" >
                          <ImLocation />
                        </div>
                        {supplierObj.companyObj?.address}
                      </a>
                    </li>
                    <li>
                      <div className="icon brown">
                        <BsFillCheckCircleFill />
                      </div>
                      {supplierObj.companyObj?.gstNumber}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="right" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              {
                supplierObj?.isVerified &&
                <img src={images.verified} style={{ width: 110, marginBottom: 20 }} alt="" />
              }
              <ul>
                <li>
                  <a
                    href={`tel:${supplierObj?.companyObj?.phone}`}
                    className="btn btn-yellow btn-custom btn-hover with-icon"
                  >
                    <FaPhoneAlt />
                    Call
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${supplierObj?.companyObj?.email}`}
                    className="btn btn-yellow btn-custom btn-hover with-icon"
                  >
                    <GrMail />
                    Send Email
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => window.open(`https://web.whatsapp.com/send?phone=${supplierObj?.companyObj?.phone}`)}
                    className="btn btn-yellow btn-custom btn-hover with-icon"
                  >
                    <AiOutlineWhatsApp />
                    Whatsapp
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

      <section className="product-tabs supplier-tabs mb-4">
        <div className="container">
          <ul className="tabs">
            {tabs.map((item, i) => {
              return (
                <li
                  className={item.active ? "active" : ""}
                  key={i}
                  onClick={() => ActiveTab(i)}
                >
                  <div className="icon">{item.icon}</div>{item.name}
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      {tabs.map((item, i) => {
        if (item.active && item.tab === "1") {
          return (
            <>
              <section className="mb-80">
                <div className="container">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    speed={1500}
                    modules={[Autoplay, Navigation]}
                    autoplay={{ disableOnInteraction: false }}
                    navigation
                  >
                    {
                      supplierObj?.imagesArr && supplierObj?.imagesArr.length > 0 && supplierObj?.imagesArr.map((el, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="product-box supplier-product-box">
                              <a target="_blank" href={generateImageUrl(el.image)}>
                                <img src={generateImageUrl(el.image)} alt="" className="img" />
                              </a>
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }

                  </Swiper>
                </div>
              </section>

              {
                supplierObj?.videoArr && supplierObj?.videoArr.length > 0 &&
                <section className="product-video mb-80">
                  <div className="container">
                    <div className="title-section with-btn mb-5">
                      <h1 className="heading bottom-line brown">
                        Our Videos
                      </h1>
                      <Link to="/" className="btn btn-custom btn-yellow">
                        View All
                      </Link>
                    </div>
                    <div className="row gy-4">

                      {
                        supplierObj?.videoArr && supplierObj?.videoArr.length > 0 && supplierObj?.videoArr.map((el, index) => {
                          return (
                            <div key={index} className="col-12 col-lg-4">
                              <div className="box">
                                <div className="image">
                                  <iframe
                                    width="100%"
                                    height="100%"
                                    src={generateImageUrl(el.video)}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    autoplay={false}
                                    allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                                {/* <div className="content flex-1">
                                <h5>Dynora Steel Plywood</h5>
                                <p>
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the
                                  industry's standard dummy text ever since the 1500s,
                                  when an unknown printer took a galley of type and
                                  scrambled it to make a type specimen book.
                                </p>
                              </div> */}
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div>
                </section>
              }
            </>
          );
        }
        if (item.active && item.tab === "2") {
          return (
            <>
              <section className="supplier-profile mb-80">
                <div className="container">
                  <p className="text-center my-5">{supplierObj?.longDescription}</p>
                  <div className="row gy-4">
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><MdAddBusiness /></div>
                        <div className="content">
                          <h5 className="brown">Nature of Business</h5>
                          <p>{supplierObj?.companyObj?.natureOfBusiness ? supplierObj?.companyObj?.natureOfBusiness : "Not provided"} </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><FaUserFriends /></div>
                        <div className="content">
                          <h5 className="brown">Total Employees</h5>
                          <p>{supplierObj?.companyObj?.noofepmployee ? supplierObj?.companyObj?.noofepmployee : "Not provided"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><BsCalendarWeek /></div>
                        <div className="content">
                          <h5 className="brown">Year of Establishment</h5>
                          <p>{supplierObj?.companyObj?.yearOfEstablishment ? supplierObj?.companyObj?.yearOfEstablishment : "Not provided"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><GiChart /></div>
                        <div className="content">
                          <h5 className="brown">Annual Turnover</h5>
                          <p>{supplierObj?.companyObj?.annualTurnover ? supplierObj?.companyObj?.annualTurnover : "Not provided"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><MdImportExport /></div>
                        <div className="content">
                          <h5 className="brown">Import Export Code (IEC)</h5>
                          <p>{supplierObj?.companyObj?.iecCode ? supplierObj?.companyObj?.iecCode : "Not provided"} </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><GiScales /></div>
                        <div className="content">
                          <h5 className="brown">Legal Status of Firm</h5>
                          <p>{supplierObj?.companyObj?.legalStatus ? supplierObj?.companyObj?.legalStatus : "Not provided"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><BsFillCheckCircleFill /></div>
                        <div className="content">
                          <h5 className="brown">GST No.</h5>
                          <p>{supplierObj?.companyObj?.gstNumber ? supplierObj?.companyObj?.gstNumber : "Not provided"} </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><BsFillCheckCircleFill /></div>
                        <div className="content">
                          <h5 className="brown">CIN No.</h5>
                          <p>{supplierObj?.companyObj?.cinNo ? supplierObj?.companyObj?.cinNo : "Not provided"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box">
                        <div className="icon brown"><ImOffice /></div>
                        <div className="content">
                          <h5 className="brown">Company CEO</h5>
                          <p>{supplierObj?.companyObj?.companyCeo ? supplierObj?.companyObj?.companyCeo : "Not provided"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          );
        }
        if (item.active && item.tab === "3") {
          return (
            <section className="category-page mb-80">
              <div className="container">
                <ul className="row">
                  {productsArr && productsArr.length > 0 ? productsArr.map((item, i) => {
                    return (
                      <li className="col-12 col-md-3">
                        <div className="product-box">
                          <button className="call-btn">
                            <MdCall />
                          </button>
                          <Link to={`/ShopDetail/${item.slug}`}>
                            <img src={generateImageUrl(item.mainImage)} alt="" className="img" />
                          </Link>
                          <div className="content">
                            <h6 className="title">
                              <Link to={`/ShopDetail/${item.slug}`}>{item.name}</Link>
                            </h6>
                            {/* <h6 className="size">Size (Sq ft): 8x4</h6> */}
                            <h6 className="prize">
                              <del className="text-secondary">₹ {item.price}</del> {item.sellingprice}{" "}
                            </h6>
                          </div>
                        </div>
                      </li>
                    );
                  })
                    :
                    <h6>No products added yet.</h6>
                  }
                </ul>
              </div>
            </section>

          );
        }
        if (item.active && item.tab === "4") {
          return (
            <div className="tab-inner mb-80">
              <div className="desp">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Explicabo hic repellendus eos, ipsam dicta excepturi eveniet
                  illum aspernatur unde nobis. Eveniet architecto, aliquid
                  officiis modi molestiae ad officia culpa ratione?Lorem ipsum
                  dolor, sit amet consectetur adipisicing elit. Explicabo hic
                  repellendus eos, ipsam dicta excepturi eveniet illum
                  aspernatur unde nobis. Eveniet architecto, aliquid officiis
                  modi molestiae ad officia culpa ratione?
                </p>

                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Explicabo hic repellendus eos, ipsam dicta excepturi eveniet
                  illum aspernatur unde nobis. Eveniet architecto, aliquid
                  officiis modi molestiae ad officia culpa ratione?
                </p>
              </div>
            </div>
          );
        }
      })}

      <Modal
        show={quoteModal}
        size="lg"
        centered
        onHide={() => setQuoteModal(false)}
      >
        <Modal.Body className="review-modal custom-modal">
          <button
            type="button"
            class="btn-close right"
            aria-label="Close"
            onClick={() => setQuoteModal(false)}
          ></button>
          <h3 className="heading brown my-2">Get a quote</h3>
          <form className="form row">
            <div className="col-12">
              <label>Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-12 col-md-6">
              <label>Email</label>
              <input type="email" className="form-control" />
            </div>
            <div className="col-12 col-md-6">
              <label>Phone No.</label>
              <input type="number" className="form-control" />
            </div>
            <div className="col-12">
              <label>Comment</label>
              <textarea rows="5" className="form-control"></textarea>
            </div>
            <div className="col-12">
              <button className="btn btn-custom text-white yellow-bg py-2">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </main>
  );
}

export default Supplier;
