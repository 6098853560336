import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { MdCall } from "react-icons/md";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { getNameBySlug } from "../services/Category.service";
import { getAllProducts } from "../services/Product.service";
import { generateImageUrl } from "../services/url.service";
import { toastError } from "../utils/toastutill";
import ShopFilter from "./ShopFilter";
import { images } from "./Utility/Images";
import PageBanner from "./Utility/PageBanner";

function Shop() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [productsArr, setProductsArr] = useState([]);
  // const [currentCategoryObj, setCurrentCategoryObj] = useState("");

  const handleGetProducts = async () => {
    try {
      let { data: res } = await getAllProducts(searchParams.toString());
      if (res.data) {
        setProductsArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetProducts();
  }, [location.search]);

  return (
    <main>
      <section className="shop-page mb-80 px-4pc mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-4 col-xl-3">
              <ShopFilter />
            </div>
            <div className="col-12 col-md-8 col-xl-9">
              <PageBanner img={images.top_banner} title="We connect Buyers & Sellers" desp="Plywood bazar is India's largest online B2B marketplace, connecting buyers with suppliers." className="mx-0" />
              <ul className="product-name my-4">
                <li>
                  <p className="text-muted">Products</p>
                </li>
                <li>
                  <div className="text-muted icon">
                    <BiChevronRight />
                  </div>
                </li>
                {/* <li>
                  <p className="brown">{currentCategoryObj.name}</p>
                </li> */}
              </ul>
              <div className="row gy-5">
                {productsArr && productsArr.length > 0 ? (
                  productsArr.map((el, index) => {
                    return (
                      <div key={index} className="col-12 col-lg-4">
                        <div className="product-box">
                          <button className="call-btn">
                            <MdCall />
                          </button>
                          {/* <Link to={`/ShopDetail/${el.slug}`}>

                            <img src={images.category_5} alt="" className="img" />
                          </Link> */}
                          <Link to={`/ShopDetail/${el.slug}`}>{el?.mainImage ? <img src={generateImageUrl(el?.mainImage)} alt="" className="img" /> : <img src={images.category_5} alt="" className="img" />}</Link>
                          <div className="content">
                            <h6 className="title">
                              <Link to={`/ShopDetail/${el.slug}`}>{el.name}</Link>
                            </h6>
                            <h6 className="size">Size (Sq ft): {el?.specification?.size ? el?.specification?.size : "N.A."}</h6>
                            <h6 className="prize">₹{el.sellingprice}/Sq ft</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h4 style={{ color: "grey" }}>No Products found for the selected category</h4>
                )}

                {/* <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_6} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_4} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_3} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_2} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_1} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_5} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_6} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_4} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_3} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_2} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="product-box">
                    <button className="call-btn">
                      <MdCall />
                    </button>
                    <Link to='/ShopDetail'><img src={images.category_1} alt="" className="img" /></Link>
                    <div className="content">
                      <h6 className="title">
                        <Link to='/ShopDetail'>Veneer Boards 18mm Waterproof Wooden Plywood Sheet, 8x4</Link>
                      </h6>
                      <h6 className="size">Size (Sq ft): 8x4</h6>
                      <h6 className="prize">₹60/Sq ft</h6>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Shop;
