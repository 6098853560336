import React, { useEffect, useState } from "react";
import { AiOutlineSafety, AiOutlineStar } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { GoMail } from "react-icons/go";
import { MdCall, MdOutlineVerified } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getForHomepage } from "../services/Advertisement.service";
import { getBrands } from "../services/Banner.service";
import { getAllCategories, getNestedCategories } from "../services/Category.service";
import { getCityApi } from "../services/city.service";
import { getAllFlashSales } from "../services/FlashSales.service";
import { generateImageUrl } from "../services/url.service";
import { addUserRequirement } from "../services/UserRequirements.service";
import { toastSuccess } from "../utils/toastutill";
import CountdownTimer from "./Utility/CountdownTimer";
import { images } from "./Utility/Images";
import { errorToast } from "./Utility/Toast";
import { getBlogVideoApi } from "../services/BlogVideo.service";
import { getBlogApi } from "../services/Blog.service";
import { BsArrowRight } from "react-icons/bs";

function Index() {
  const [categoryArr, setcategoryArr] = useState([]);
  const [brandArr, setbrandArr] = useState([]);
  const [categoryProductArr, setcategoryProductArr] = useState([]);
  const [categoryProductTopArr, setcategoryProductTopArr] = useState([]);
  const [categoryProductBelowArr, setcategoryProductBelowArr] = useState([]);
  const [flashSalesArr, setFlashSalesArr] = useState([]);
  const [citiesArr, setCitiesArr] = useState([]);
  const auth = useSelector((state) => state.auth.user);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [productName, setProductName] = useState("");
  const [advertisementsArr, setAdvertisementsArr] = useState([]);



  const [blogsArr, setBlogsArr] = useState([]);
  const [blogVideoArr, setBlogVideoArr] = useState([]);

  const handleGetBlogs = async () => {
    try {
      let { data: res } = await getBlogApi();
      if (res.data) {
        setBlogsArr(res.data);
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  const handleGetBlogVideo = async () => {
    try {
      let { data: res } = await getBlogVideoApi();
      if (res.data) {
        setBlogVideoArr(res.data);
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    handleGetBlogs()
    handleGetBlogVideo()
  }, [])




  const navigate = useNavigate();

  const handleSubmitRequirement = async (e) => {
    try {
      if (name == "") {
        errorToast("Name cannot be empty");
        return;
      }
      if (phone == "") {
        errorToast("Mobile number cannot be empty");
        return;
      }
      if (address == "") {
        errorToast("Address cannot be empty");
        return;
      }
      if (productName == "") {
        errorToast("Product cannot be empty");
        return;
      }

      e.preventDefault();
      let obj = {
        name,
        phone,
        address,
        productName,
        userId: auth._id,
      };
      let { data: res } = await addUserRequirement(obj);
      if (res.message) {
        toastSuccess(res.message);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetSubscriptions = async () => {
    try {
      let { data: res } = await getAllFlashSales();
      if (res.data) {
        // console.log(res.data, "flash sales");
        setFlashSalesArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleNestedcategories = async () => {
    try {
      let { data: res } = await getNestedCategories();
      if (res.data && res.data?.length > 0) {
        let arrays = [],
          size = 2;
        while (res.data.length > 0) arrays.push(res.data.splice(0, size));
        setcategoryArr(arrays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategories = async () => {
    try {
      let { data: res } = await getAllCategories("level=1&products=true");
      if (res.data && res.data?.length > 0) {
        setcategoryProductArr(res.data);
        setcategoryProductTopArr(res.data.slice(0, 2));
        setcategoryProductBelowArr(res.data.slice(2, 3));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBrands = async () => {
    try {
      let { data: res } = await getBrands("status=true");
      if (res.data && res.data?.length > 0) {
        setbrandArr(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCities = async () => {
    try {
      let { data: res } = await getCityApi();
      if (res.data) {
        setCitiesArr(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAdvvertisementForHomepage = async () => {
    try {
      let { data: res } = await getForHomepage();
      if (res.data) {
        console.log(res.data, "data");
        setAdvertisementsArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };
  useEffect(() => {
    handleGetAdvvertisementForHomepage();
    handleNestedcategories();
    handleCategories();
    handleBrands();
    handleGetCities();
    handleGetSubscriptions();
  }, []);

  return (
    <main>
      <section className="main-banner mb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="content">
                <h1 className="heading">We connect Buyers & Sellers</h1>
                <p className="desp">Plywood bazar is India's largest online B2B marketplace, connecting buyers with suppliers.</p>
                <ul className="list">
                  <li>
                    <div className="icon">
                      <AiOutlineStar />
                    </div>
                    <h6>Trusted Platform</h6>
                  </li>
                  <li>
                    <div className="icon">
                      <AiOutlineSafety />
                    </div>
                    <h6>Safe & Secure</h6>
                  </li>
                  <li>
                    <div className="icon">
                      <RiMessage2Line />
                    </div>
                    <h6>Quick Assistance</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="category mb-80">
        <div className="container">
          <div className="title-section with-btn mb-5">
            <h1 className="heading bottom-line brown">Our Categories</h1>
            <Link to="/shop" className="btn btn-custom btn-yellow">
              View All
            </Link>
          </div>
        </div>

        {categoryArr && (
          <ul className="row g-0">
            {categoryArr &&
              categoryArr.map((categoryChuk, index) => (
                <>
                  {![0, 3, 6].includes(index) ? (
                    <li className="col-12 col-md-3">
                      {categoryChuk &&
                        categoryChuk.map((category) => (
                          <div className="box">
                            <Link to={`Shop?categories=${category._id}`}>
                              <img src={generateImageUrl(category.image)} alt="" />
                            </Link>
                            <div className="text">
                              <Link to={`Shop?categories=${category._id}`}>
                                <h5>{category.name}</h5>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </li>
                  ) : (
                    <li className="col-12 col-md-6">
                      {categoryChuk &&
                        categoryChuk.map((category) => (
                          <div className="box">
                            <Link to={`Shop?categories=${category._id}`}>
                              <img src={generateImageUrl(category.image)} alt="" />
                            </Link>
                            <div className="text">
                              <Link to={`Shop?categories=${category._id}`}>
                                <h5>{category.name}</h5>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </li>
                  )}
                </>
              ))}
          </ul>
        )}
      </section>

      <section className="gray-bg mb-80  ptb-80 px-4pc">
        <div className="container">
          <div className="text-center mb-5">
            <h1 className="heading bottom-line brown">Featured Products</h1>
          </div>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView={5}
            autoplay={{ disableOnInteraction: false }}
            speed={1500}
          // loop
          >
            {advertisementsArr &&
              advertisementsArr.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="vender-box">
                      <div className="inner">
                        {
                          el.isVideo ?
                            <video src={generateImageUrl(el.image)} className="img" height={140} width={"100%"} />
                            :
                            <img src={generateImageUrl(el.image)} alt="" className="img" />
                        }
                        {/* <img src={generateImageUrl(el.image)} alt="" className="img" /> */}
                        <h6 className="title">{el.message}</h6>
                      </div>
                      <Link to={`/ShopDetail/${el?.productSlug}`} className="btn btn-custom btn-yellow">
                        Get Quotes
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>
      {categoryProductTopArr &&
        categoryProductTopArr.map((category) => (
          <section className="products mb-80">
            <div className="container">
              <div className="title-section with-btn mb-5">
                <h1 className="heading bottom-line brown">{category.name} </h1>
                <Link to={`/shop?categories=${category._id}`} className="btn btn-custom btn-yellow">
                  View All
                </Link>
              </div>
              {category?.productArr && (
                <ul className="row">
                  {category?.productArr.map((product) => (
                    <li className="col-12 col-md-3">
                      <div className="product-box">
                        <button className="call-btn">
                          <MdCall />
                        </button>
                        <Link to={`/ShopDetail/${product.slug}`}>
                          <img src={generateImageUrl(product.mainImage)} alt="" className="img" />
                        </Link>
                        <div className="content">
                          <h6 className="title">
                            <Link to={`/ShopDetail/${product.slug}`}>{product.name}</Link>
                          </h6>
                          {/* <h6 className="size">Size (Sq ft): 8x4</h6> */}
                          <h6 className="prize">
                            <del className="text-secondary">₹ {product.price}</del> {product.sellingprice}{" "}
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
        ))}

      <section className="flash-sale mb-80 px-4pc gray-bg ptb-80">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-12">
              {/* <div className="view-all text-end mb-4">
                <Link to="/" className="btn btn-custom btn-yellow">
                  View All
                </Link>
              </div> */}
              <Swiper modules={[Autoplay]} spaceBetween={20} slidesPerView={5} autoplay={{ disableOnInteraction: false }} speed={1500}>
                {flashSalesArr &&
                  flashSalesArr.length > 0 &&
                  flashSalesArr.map((el, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="product-box-2">
                          <div className="position-relative">
                            <CountdownTimer targetDate={el.endDate} />
                            <Link to={`/ShopDetail/${el?.productId?.slug}`}>
                              <img src={generateImageUrl(el.productId.mainImage)} alt="" className="img" />
                              <div className="overlyasper"></div>
                            </Link>
                            <h6 className="title">
                              <Link to={`/ShopDetail/${el?.productId?.slug}`}>{el?.productId?.name}</Link>
                            </h6>
                            <ul className="tags">
                              <li>{el.discountType == "Percentage" ? `${el.discountValue}% OFF` : `Flat ${el.discountValue} OFF`}</li>
                            </ul>
                          </div>

                          <div className="content">
                            <div>
                              <h6 className="old">
                                <span className="prize">₹{el?.price}</span>
                                <span className="small text-muted">/ Sq ft</span>
                              </h6>
                              <h6 className="new">
                                <span className="prize">₹{el?.salePrice}</span>
                                <span className="small text-muted">/ Sq ft</span>
                              </h6>
                            </div>
                            <button className="call-btn">
                              <MdCall />
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {categoryProductBelowArr &&
        categoryProductBelowArr.map((category) => (
          <section className="products mb-80">
            <div className="container">
              <div className="title-section with-btn mb-5">
                <h1 className="heading bottom-line brown">{category.name}</h1>
                <Link to={`/shop?categories=${category._id}`} className="btn btn-custom btn-yellow">
                  View All
                </Link>
              </div>
              <ul className="row">
                {category?.productArr && (
                  <ul className="row">
                    {category?.productArr.map((product) => {
                      console.log(product, "product");
                      return (
                        <li className="col-12 col-md-3">
                          <div className="product-box">
                            <button className="call-btn">
                              <MdCall />
                            </button>
                            <Link to={`/ShopDetail/${product.slug}`}>
                              <img src={images.category_5} alt="" className="img" />
                            </Link>
                            <div className="content">
                              <h6 className="title">
                                <Link to={`/ShopDetail/${product.slug}`}>{product.name}</Link>
                              </h6>
                              {/* <h6 className="size">Size (Sq ft): 8x4</h6> */}
                              <h6 className="prize">
                                <del className="text-secondary">₹ {product.price}</del> ₹ {product.sellingprice}
                              </h6>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </ul>
            </div>
          </section>
        ))}

      <section className=" gray-bg mb-80  ptb-80 px-4pc">
        <div className="container">
          <div className="text-center mb-5">
            <h1 className="heading bottom-line brown">Find Suppliers from Top Cities</h1>
          </div>
          <Swiper modules={[Autoplay]} spaceBetween={20} slidesPerView={6} autoplay={{ disableOnInteraction: false }} speed={1500}>
            {citiesArr &&
              citiesArr.length > 0 &&
              citiesArr.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div onClick={() => navigate(`/shop?locations=${el._id}`)} className="find-supplier">
                      <img src={el.image ? generateImageUrl(el.image) : images.category_5} alt="" className="img" />
                      <h6 className="title">{el?.name}</h6>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>
      <section className=" mb-80  ptb-80 px-4pc">
        <div className="container">
          <div className="title-section with-btn mb-5">
            <h1 className="heading bottom-line brown">Our Blogs</h1>
            <Link to={`/View/blogs`} className="btn btn-custom btn-yellow">
              View All
            </Link>
          </div>
          <Swiper modules={[Autoplay]} spaceBetween={20} slidesPerView={4} autoplay={{ disableOnInteraction: false }} speed={1500}>
            {blogsArr &&
              blogsArr.length > 0 &&
              blogsArr.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div>
                      <div className="blog_listing">
                        <div className="blog_listing_img">
                          <img src={generateImageUrl(el.image)} alt="" className="img-fluid blogImage" />
                        </div>
                        <div className="list_content_blog">
                          <h6>{el?.name}</h6>
                          {/* <h4> How to Secure Your Ecommerce Website from Cyberattacks</h4> */}
                          <div dangerouslySetInnerHTML={{ __html: el?.description.slice(0, 100) }}></div>
                          <Link to={`/blog-detail/${el._id}`} className="btn blog_readmore">Read More <BsArrowRight className="blog_arrow" /></Link>
                        </div>
                      </div>
                    </div>
                    {/* <div onClick={() => navigate(`/shop?locations=${el._id}`)} className="find-supplier">
                      <img src={el.image ? generateImageUrl(el.image) : images.category_5} alt="" className="img" />
                      <h6 className="title">{el?.name}</h6>
                    </div> */}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>
      <section className=" gray-bg mb-80  ptb-80 px-4pc">
        <div className="container">
          <div className="title-section with-btn mb-5">
            <h1 className="heading bottom-line brown">Our Videos</h1>
            <Link to={`/View/blogs`} className="btn btn-custom btn-yellow">
              View All
            </Link>
          </div>
          <Swiper modules={[Autoplay]} spaceBetween={20} slidesPerView={4} autoplay={{ disableOnInteraction: false }} speed={1500}>
            {blogVideoArr &&
              blogVideoArr.length > 0 &&
              blogVideoArr.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    {
                      el.url && el.url.includes("http") &&
                      <iframe src={(el.url)} frameborder="0" className="img-fluid blogImage"></iframe>
                    }
                    {/* <div onClick={() => navigate(`/shop?locations=${el._id}`)} className="find-supplier">
                      <img src={el.image ? generateImageUrl(el.image) : images.category_5} alt="" className="img" />
                      <h6 className="title">{el?.name}</h6>
                    </div> */}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>

      {/* <section className="mb-80  ptb-80 px-4pc">
        <div className="container-fluid">
          <div className="text-center mb-5">
            <h1 className="heading bottom-line brown">
              Explore products from Featured Brands
            </h1>
          </div>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView={6}
            autoplay={{ disableOnInteraction: false }}
            speed={1500}
            loop
          >
            {
              brandArr && brandArr.map((brand) => (
                <SwiperSlide>
                  <div className="clients-box">
                    <img src={generateImageUrl(brand.image)} alt="" className="img" />
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </section> */}

      <section className="mb-80">
        <div className="container">
          <div className="text-center mb-5 pb-5">
            <h1 className="heading bottom-line brown">More for you</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="more-box">
                <div className="icon">
                  <BiStore />
                </div>
                <div className="content">
                  <h4>Sell on Plywood Bazar for free</h4>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut .</p>
                  <Link to="/" className="btn btn-custom btn-brown">
                    Start Selling
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="more-box">
                <div className="icon">
                  <MdOutlineVerified />
                </div>
                <div className="content">
                  <h4>Connect with verified sellers.</h4>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut .</p>
                  <Link to="/" className="btn btn-custom btn-brown">
                    Get Verified Sellers
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="more-box">
                <div className="icon">
                  <GiPayMoney />
                </div>
                <div className="content">
                  <h4>Pay with plywood Bazar.</h4>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut .</p>
                  <Link to="/" className="btn btn-custom btn-brown">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb-80 contact-us">
        <div className="container">
          <div className="row gx-lg-5">
            <div className="col-12 col-md-6">
              <div className="left">
                <h1 className="heading">Get free quotes from multiple sellers</h1>
                <ul className="list">
                  <li>
                    <div className="icon">
                      <FaRegHandshake />
                    </div>
                    <h6>Tell us whatYou</h6>
                  </li>
                  <li>
                    <div className="icon">
                      <GoMail />
                    </div>
                    <h6>Receive free quotesfrom sellers</h6>
                  </li>
                  <li>
                    <div className="icon">
                      <RiMessage2Line />
                    </div>
                    <h6>Tell us whatYou Need</h6>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="right">
                <h3 className="heading yellow">Tell us your Requirement</h3>
                <form className="form row">
                  <div className="col-12">
                    <label>Name</label>
                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label>Mobile No.</label>
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label>Address</label>
                    <input value={address} onChange={(e) => setAddress(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label>Product / Service</label>
                    <input value={productName} onChange={(e) => setProductName(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <button className="btn btn-custom btn-yellow mt-2" onClick={(e) => handleSubmitRequirement(e)}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Index;
