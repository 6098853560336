import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import { getBrandApi } from "../../services/brand.service";
import { getAllCategories } from "../../services/Category.service";
import { AddProduct, getProductById, updateProductApi } from "../../services/Product.service";
import { generateImageUrl } from "../../services/url.service";
import { ROLES_CONSTANT } from "../Utility/constant";
import FileUpload from "../Utility/FileUpload";
import { errorToast, successToast } from "../Utility/Toast";

export default function AddProducts() {

    const dispatch = useDispatch()
    const { id } = useParams()
    const [productId, setProductId] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [isEditingModeOn, setIsEditingModeOn] = useState(false);
    const [brand, setbrand] = useState("")
    const [category, setcategory] = useState("")
    const [name, setname] = useState("")
    const [thickness, setthickness] = useState("")
    const [application, setapplication] = useState("")
    const [grade, setgrade] = useState("")
    const [color, setcolor] = useState("")
    const [size, setsize] = useState("")
    const [wood, setwood] = useState("")
    const [glue, setglue] = useState("")
    const [price, setprice] = useState("")
    const [sellingprice, setsellingprice] = useState("")
    const [warranty, setwarranty] = useState("")
    const [shortDescription, setshortDescription] = useState("")
    const [longDescription, setLongDescription] = useState()
    const [image, setimage] = useState()
    const [status, setstatus] = useState(false)
    const [isUpdated, setisUpdated] = useState(false)
    const [updateObj, setupdateObj] = useState({})
    const [imageArr, setimageArr] = useState([
        {
            image: ""
        }
    ])

    useEffect(() => {
        handleGetBrands()
        handleGetCategory()
    }, [])

    const [mainCategoryArr, setmainCategoryArr] = useState([])

    const handleFileSet = (value, index) => {
        const tempArr = imageArr

        tempArr[index].image = value
        setimageArr([...tempArr])
    }

    // useEffect(() => {
    //     if (productRedux !== null) {
    //         setname(productRedux?.name)
    //         setprice(productRedux?.price)
    //         setsellingprice(productRedux?.sellingprice)
    //         setcategory(productRedux?.categoryId)
    //         setbrand(productRedux?.brand)
    //         setthickness(productRedux?.specification?.thickness)
    //         setapplication(productRedux?.specification?.application)
    //         setgrade(productRedux?.specification?.grade)
    //         setcolor(productRedux?.specification?.color)
    //         setwood(productRedux?.specification?.wood)
    //         setglue(productRedux?.specification?.glue)
    //         setwarranty(productRedux?.specification?.warranty)
    //         setshortDescription(productRedux?.shortDescription)
    //         setLongDescription(productRedux?.longDescription)
    //         setimage(productRedux?.mainImage)
    //         setstatus(productRedux?.status)
    //         setisUpdated(true)
    //         setupdateObj(productRedux)
    //         setimageArr(productRedux?.imageArr)
    //         setmainCategoryArr(productRedux?.categoryArr)


    //     }
    // }, [productRedux])

    const handleCategoryEvent = (id) => {
        const tempCategoryArr = []
        categoryArr.map(el => {
            if (el._id === id) {
                tempCategoryArr.push({ categoryId: el._id })
            } else {
                if (el.subCategoryArr && el.subCategoryArr.length > 0) {
                    el.subCategoryArr.map(elx => {
                        if (elx._id === id) {
                            tempCategoryArr.push({ categoryId: el._id })
                            tempCategoryArr.push({ categoryId: elx._id })
                        }
                    }
                    )
                }
            }
        }
        )

        setcategory(id)
        setmainCategoryArr([...tempCategoryArr])
    }

    const onSubmit = async () => {
        try {

            if (`${name}` === '') {
                errorToast('Please Fill Name')
                return 0
            }
            if (`${category}` === '') {
                errorToast('Please Fill Category')
                return 0
            }
            if (`${sellingprice}` === '' || parseInt(sellingprice) <= 0) {
                errorToast('Please Fill Selling Price')
                return 0
            }
            if (`${price}` === '' || parseInt(price) <= 0) {
                errorToast('Please Fill Price')
                return 0
            }
            if (`${thickness}` === '') {
                errorToast('Please Fill Thickness')
                return 0
            }
            if (`${application}` === '') {
                errorToast('Please Fill Application')
                return 0
            }

            if (`${color}` === '') {
                errorToast('Please Fill Color')
                return 0
            }

            if (`${shortDescription}` === '') {
                errorToast('Please Fill shortDescription')
                return 0
            }

            if (`${image}` === '') {
                errorToast('Please add main imgae')
                return 0
            }
            if (imageArr && imageArr.length > 1) {
                if (imageArr.some(el => !el.image || el.image == "")) {
                    errorToast('canot upload blank image')
                    return 0
                }
            }

            let obj = {
                name: name,
                categoryId: category,
                brand: brand,
                price: price,
                sellingprice: sellingprice,
                specification: {
                    thickness,
                    application,
                    grade,
                    color,
                    wood,
                    glue,
                    warranty
                },
                shortDescription: shortDescription,
                longDescription: longDescription,
                status: status,
                image: image,
                imageArr: imageArr,
                categoryArr: mainCategoryArr,
            }

            if (isEditingModeOn) {
                let { data: res } = await updateProductApi(obj, productId)
                if (res.message) {
                    successToast(res.message)
                }
            }
            else {

                let { data: res } = await AddProduct(obj)
                if (res.message) {
                    successToast(res.message)
                }
            }

        }
        catch (err) {
            errorToast(err)
        }

    }
    const subcategoryRender = (cateArr, dash) => {
        dash += '- '
        console.log(cateArr && cateArr?.length)
        return (
            cateArr && cateArr.length > 0 && cateArr.map((cat) => {
                return (
                    <>
                        <option key={cat._id} value={cat._id}>{dash}{cat.name}</option>
                        {subcategoryRender(cat.subCategoryArr, dash)}
                    </>

                )
            })
        )
    }

    const handleImageAdd = () => {
        setimageArr([...imageArr, { image: "" }])
    }
    const handleImagesRemove = (removeIndex) => {
        if (imageArr.length > 1) {
            setimageArr([
                ...imageArr.filter(
                    (el, index) => index != (imageArr.length - 1)
                )
            ])
        }
    }



    const [categoryArr, setCategoryArr] = useState([]);
    const [brandArr, setBrandArr] = useState([]);

    const handleGetBrands = async () => {
        try {
            let { data: res } = await getBrandApi('status=true')
            if (res.data) {
                setBrandArr(res.data)
            }
        }
        catch (err) {
            errorToast(err)
        }
    }

    const handleGetCategory = async () => {
        try {
            let { data: res } = await getAllCategories()
            if (res.data) {
                setCategoryArr(res.data)
            }
        }
        catch (err) {
            errorToast(err)
        }
    }


    const handleGetProductById = async () => {
        try {
            let { data: res } = await getProductById(searchParams.get("productId"))
            if (res.data) {
                console.log(res.data)
                setname(res?.data?.name)
                setProductId(res?.data?._id)
                setprice(res.data?.price)
                setsellingprice(res.data?.sellingprice)
                setcategory(res.data?.categoryId)
                setbrand(res.data?.brand)
                setthickness(res.data?.specification?.thickness)
                setapplication(res.data?.specification?.application)
                setgrade(res.data?.specification?.grade)
                setcolor(res.data?.specification?.color)
                setwood(res.data?.specification?.wood)
                setglue(res.data?.specification?.glue)
                setwarranty(res.data?.specification?.warranty)
                setshortDescription(res.data?.shortDescription)
                setLongDescription(res.data?.longDescription)
                setimage(res.data?.mainImage)
                setstatus(res.data?.status)
                // setisUpdated(true)
                setupdateObj(res.data)
                setimageArr(res.data?.imageArr)
                setmainCategoryArr(res.data?.categoryArr)
            }
        }
        catch (err) {
            errorToast(err)
        }
    }


    const handleCtegoryEvent = (id) => {
        const tempCategoryArr = []
        categoryArr.map(el => {
            if (el._id === id) {
                tempCategoryArr.push({ categoryId: el._id })
            } else {
                if (el.subCategoryArr && el.subCategoryArr?.length > 0) {
                    el.subCategoryArr.map(elx => {
                        if (elx._id === id) {
                            tempCategoryArr.push({ categoryId: el._id })
                            tempCategoryArr.push({ categoryId: elx._id })
                        }
                    }
                    )
                }
            }
        }
        )

        setcategory(id)
        setmainCategoryArr([...tempCategoryArr])
    }


    useEffect(() => {
        if (searchParams.get("productId")) {
            setIsEditingModeOn(true)
            handleGetProductById()
        }
    }, [searchParams.get("productId")])

    return (
        <div className="container">
            <div className="row m-3 pt-3">
                <div className="col-12 col-md-12">
                    <div className="right">
                        <h3 className="heading yellow">{isEditingModeOn ? "Edit" : "Add"} Product</h3>
                        <form className="form row">
                            <h4 className="yellow">Product Details </h4>
                            <div className="col-md-6">
                                <label>Name  <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Category <span className="text-danger">*</span></label>

                                <select className='form-control' value={category} onChange={(e) => handleCtegoryEvent(e.target.value)}>
                                    <option value="">Please Select Category</option>
                                    {categoryArr && categoryArr.length > 0 && subcategoryRender(categoryArr, '-')}
                                </select>

                                {/* <ReactSelect onChange={(e) => setcategory(e.value)} value={category} defaultInputValue={categoryArr.find(el => el._id == category) ? categoryArr.find(el => el._id == category) : {}} options={categoryArr && categoryArr.length > 0 && categoryArr.map(el => ({ label: el.name, value: el._id }))} /> */}

                                {/* <input
                                    type="tel"
                                    className="form-control"
                                    value={mobile}
                                    onChange={(e) => setmobile(e.target.value)}
                                    maxLength="10"
                                /> */}
                            </div>
                            <div className="col-md-6">
                                <label>Brand</label>
                                <select className='form-control' value={brand} onChange={(e) => setbrand(e.target.value)}>
                                    <option value="">Please Select Brand</option>
                                    {brandArr && brandArr.length > 0 && brandArr.map(el => <option value={el._id}>{el.name}</option>)}
                                </select>
                                {/* <ReactSelect onChange={(e) => setbrand(e.value)} options={brandArr && brandArr.length > 0 && brandArr.map(el => ({ label: el.name, value: el._id }))} /> */}

                                {/* <input
                                    type="tel"
                                    className="form-control"
                                    value={whatsapp}
                                    onChange={(e) => setwhatsapp(e.target.value)}
                                    maxLength="10"
                                /> */}
                            </div>
                            <div className="col-md-6">
                                <label>Price <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={price}
                                    onChange={(e) => setprice(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Selling Price <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={sellingprice}
                                    onChange={(e) => setsellingprice(e.target.value)}
                                />
                            </div>
                            <hr className="my-4" />
                            <h4 className="yellow">Specification</h4>
                            <div className="col-md-6">
                                <label>Thickness <span className="text-danger">*</span></label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={thickness}
                                    onChange={(e) => setthickness(e.target.value)}
                                    maxLength="10"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Usage/Application</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={application}
                                    onChange={(e) => setapplication(e.target.value)}
                                    maxLength="10"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Grade<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={grade}
                                    onChange={(e) => setgrade(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Color <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={color}
                                    onChange={(e) => setcolor(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Wood Type <span className="text-danger">*</span></label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={wood}
                                    onChange={(e) => setwood(e.target.value)}
                                    maxLength="10"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Glue Used</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={glue}
                                    onChange={(e) => setglue(e.target.value)}
                                    maxLength="10"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Warranty <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={warranty}
                                    onChange={(e) => setwarranty(e.target.value)}
                                />
                            </div>
                            <hr className="my-4" />
                            <h4 className="yellow">Description</h4>

                            <div className="col-md-6">
                                <label>Short Description  <span className="text-danger">*</span></label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={shortDescription}
                                    onChange={(e) => setshortDescription(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Long Description <span className="text-danger">*</span></label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={longDescription}
                                    onChange={(e) => setLongDescription(e.target.value)}
                                // maxLength="10"
                                />
                            </div>

                            <hr className="my-4" />
                            <h4 className="yellow">Product Images</h4>

                            <div className="col-md-6">
                                <label>Main Image</label>
                                {
                                    image?.includes("base64") ?
                                        <img style={{ height: 100 }} src={image} alt="" />
                                        :
                                        <img src={generateImageUrl(image)} style={{ height: 100 }} alt="" />
                                }
                                <FileUpload onFileChange={setimage} />
                            </div>

                            <hr />

                            <div className="col-md-12 col-12">
                                <div className="row">
                                    <label className="col mt-2">Muptiple Image <span className="text-danger">*</span></label>
                                    <div className="col-2">
                                        <button type="button" onClick={() => { handleImageAdd() }} className="btn btn-custom btn-yellow mt-2 me-3">
                                            +
                                        </button>
                                        <button type="button" onClick={() => { handleImagesRemove() }} className="btn btn-custom btn-yellow mt-2">
                                            -
                                        </button>
                                    </div>
                                </div>

                                <div className="row mt-4">

                                    {
                                        imageArr && imageArr.length > 0 && imageArr.map((el, index) => {
                                            return (
                                                <div className={"col-4"}>
                                                    <div style={{ height: 100 }}>
                                                        {
                                                            el.image.includes("base64") ?
                                                                <img style={{ height: 100 }} src={el?.image} alt="" />
                                                                :
                                                                <img src={generateImageUrl(el?.image)} style={{ height: 100 }} alt="" />
                                                        }
                                                    </div>

                                                    <FileUpload key={index} onFileChange={(v) => handleFileSet(v, index)} />
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>


                            <div className="col-md-12">
                                <button type="button" onClick={() => { onSubmit() }} className="btn btn-custom btn-yellow mt-2">
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
