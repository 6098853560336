import React, { useEffect, useState } from "react";
import { registerUser } from "../services/User.service";
import { ROLES_CONSTANT } from "./Utility/constant";
import FileUpload from "./Utility/FileUpload";
import { errorToast, successToast } from "./Utility/Toast";
import { getCityByStateApi, getCountriesApi, getStateByCountryApi } from "../services/location.service";

export const Register = () => {
    const [name, setname] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [whatsapp, setwhatsapp] = useState("");
    const [type, settype] = useState(ROLES_CONSTANT.USER);
    const [companyName, setcompanyName] = useState("");
    const [companyEmail, setcompanyEmail] = useState("");
    const [companyPhone, setcompanyPhone] = useState("");
    const [gstNumber, setgstNumber] = useState("");
    const [address, setaddress] = useState("");
    const [dob, setdob] = useState("");
    const [noofepmployee, setnoofepmployee] = useState("");
    const [profileImage, setprofileImage] = useState("");
    const [signature, setsignature] = useState("");
    const [gstCertificate, setgstCertificate] = useState("");
    const [countryArr, setcountryArr] = useState([]);
    const [stateArr, setstateArr] = useState([]);
    const [cityArr, setcityArr] = useState([]);
    const [countryId, setcountryId] = useState("");
    const [stateId, setstateId] = useState("");
    const [cityId, setcityId] = useState("");

    ///////new Fields///////
    const [natureOfBusiness, setNatureOfBusiness] = useState();
    const [annualTurnover, setAnnualTurnover] = useState();
    const [iecCode, setIecCode] = useState();
    const [yearOfEstablishment, setYearOfEstablishment] = useState();
    const [legalStatus, setLegalStatus] = useState();
    const [cinNo, setCinNo] = useState();
    const [companyCeo, setCompanyCeo] = useState();
    const [googleMapsLink, setGoogleMapsLink] = useState();

    const handleRegister = async () => {


        if (`${name}` === "") {
            errorToast("Name is Required");
            return;
        }
        if (`${mobile}` === "") {
            errorToast("Mobile is Required");
            return 0;
        }
        if (`${email}` === "") {
            errorToast("Email is Required");
            return 0;
        }
        if (`${type}` !== 'USER') {
            if (`${companyName}` === "") {
                errorToast("Company Name is Required");
                return 0;
            }

            if (`${companyEmail}` === "") {
                errorToast("Company Email is Required");
                return 0;
            }
            if (`${companyPhone}` === "") {
                errorToast("Company Phone is Required");
                return 0
            }
            if (`${gstNumber}` === "") {
                errorToast("Gst is Required");
                return 0;
            };
            if (`${address}` === "") {
                errorToast("Address is Required");
                return 0;
            };
            if (`${countryId}` === "") {
                errorToast("Country is Required");
                return 0;
            };
            if (`${stateId}` === "") {
                errorToast("State is Required");
                return 0;
            };
            if (`${cityId}` === "") {
                errorToast("City is Required");
                return 0;
            };
            if (`${gstCertificate}` === "") {
                errorToast("Gst Certificate is Required");
                return 0;
            };
            if (`${natureOfBusiness}` === "") {
                errorToast("Nature Of Business is Required");
                return 0;
            };
            if (`${annualTurnover}` === "") {
                errorToast("Annual Turnover is Required");
                return 0;
            };
            if (`${iecCode}` === "") {
                errorToast("IEC Code is Required");
                return 0;
            };
            if (`${yearOfEstablishment}` === "") {
                errorToast("Year of Establishment is Required");
                return 0;
            };
            if (`${legalStatus}` === "") {
                errorToast("Legal Status is Required");
                return 0;
            };
            if (`${cinNo}` === "") {
                errorToast("CIN No is Required");
                return 0;
            };
            if (`${companyCeo}` === "") {
                errorToast("Company Ceo Name is Required");
                return 0;
            };
            if (`${googleMapsLink}` === "") {
                errorToast("Google Maps Link Name is Required");
                return 0;
            };






        }


        let obj = {
            name,
            email,
            phone: mobile,
            address,
            whatsapp,
            dob,
            role: type,
            gstNumber,
            countryId,
            stateId,
            cityId,
            companyObj: {
                name: companyName,
                email: companyEmail,
                phone: companyPhone,
                address,
                gstNumber,
                noofepmployee,
                natureOfBusiness,
                annualTurnover,
                iecCode,
                yearOfEstablishment,
                legalStatus,
                cinNo,
                companyCeo,
                googleMapsLink,
            },
            gstCertificate
        }

        console.log(obj, "companycompanycompanycompany")
        try {
            let { data: res } = await registerUser(obj)
            if (res.data) {
                successToast(res.message)
                window.location.reload();
            }
        } catch (error) {
            console.error(error)
            errorToast(error)
        }
    }

    const handleGetCoutries = async () => {
        try {
            let { data: res } = await getCountriesApi();
            console.log(res.data, "data")
            if (res.data) {
                setcountryArr(res.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetStates = async (countryId) => {
        try {
            let { data: res } = await getStateByCountryApi(`countryId=${countryId}`);
            if (res.data) {
                setstateArr(res.data);
            } else {
                setstateArr([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetCities = async (stateId) => {
        try {
            let { data: res } = await getCityByStateApi(`stateId=${stateId}`);
            if (res.data) {
                setcityArr(res.data);
            } else {
                setcityArr([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleGetCoutries()
    }, [])
    useEffect(() => {
        if (countryId) {
            handleGetStates(countryId)
        }
    }, [countryId])

    useEffect(() => {
        if (stateId) {
            handleGetCities(stateId)
        }
    }, [stateId])
    return (
        <div className="container">
            <div className="row m-3 pt-3">
                <div className="col-12 col-md-12">
                    <div className="right">
                        <h3 className="heading yellow">Register</h3>
                        <form className="form row">
                            <h4 className="heading yellow">Personal Details </h4>
                            <div className="col-md-6">
                                <label>Name  <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Mobile No. <span className="text-danger">*</span></label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={mobile}
                                    onChange={(e) => setmobile(e.target.value)}
                                    maxLength="10"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Whatsapp No.</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={whatsapp}
                                    onChange={(e) => setwhatsapp(e.target.value)}
                                    maxLength="10"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Email <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>DOB</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={dob}
                                    onChange={(e) => setdob(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Type <span className="text-danger">*</span>  </label>
                                <input
                                    type="radio"
                                    name="type"
                                    value={ROLES_CONSTANT.USER}
                                    checked={type === ROLES_CONSTANT.USER}
                                    onChange={(e) => settype(e.target.value)}
                                />{" "}
                                <b className="mx-2">User</b>
                                <input
                                    type="radio"
                                    name="type"
                                    value={ROLES_CONSTANT.MANUFACTURER}
                                    checked={type === ROLES_CONSTANT.MANUFACTURER}
                                    onChange={(e) => settype(e.target.value)}
                                />{" "}
                                <b className="mx-2">MANUFACTURER</b>
                                <input
                                    type="radio"
                                    name="type"
                                    value={ROLES_CONSTANT.DISTRIBUTER}
                                    checked={type === ROLES_CONSTANT.DISTRIBUTER}
                                    onChange={(e) => settype(e.target.value)}
                                />{" "}
                                <b className="mx-2">DISTRIBUTER</b>
                                <input
                                    type="radio"
                                    name="type"
                                    value={ROLES_CONSTANT.DEALER}
                                    checked={type === ROLES_CONSTANT.DEALER}
                                    onChange={(e) => settype(e.target.value)}
                                />{" "}
                                <b className="mx-2">DEALER</b>
                            </div>
                            {

                                type !== ROLES_CONSTANT.USER && (

                                    <div className="row">
                                        <h4 className="heading yellow">Company Details </h4>

                                        <div className="col-md-6">
                                            <label> Company Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={companyName}
                                                onChange={(e) => setcompanyName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Company  Email <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={companyEmail}
                                                onChange={(e) => setcompanyEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> Company Phone <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={companyPhone}
                                                onChange={(e) => setcompanyPhone(e.target.value)}
                                                maxLength="10"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> GST No <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={gstNumber}
                                                onChange={(e) => setgstNumber(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> No of Emplyoees <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={noofepmployee}
                                                onChange={(e) => setnoofepmployee(e.target.value)}
                                            />
                                        </div>
















                                        <div className="col-md-6">
                                            <label> Nature of your business <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={natureOfBusiness}
                                                onChange={(e) => setNatureOfBusiness(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> Approximation of your Annual Turnover<span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={annualTurnover}
                                                onChange={(e) => setAnnualTurnover(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> IEC Code <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={iecCode}
                                                onChange={(e) => setIecCode(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> Year of Establishment <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={yearOfEstablishment}
                                                onChange={(e) => setYearOfEstablishment(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> Legal Status of your company<span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={legalStatus}
                                                onChange={(e) => setLegalStatus(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> CIN number <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={cinNo}
                                                onChange={(e) => setCinNo(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> Company Ceo Name <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={companyCeo}
                                                onChange={(e) => setCompanyCeo(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> Google Maps Link <span className="text-danger">*</span> </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={googleMapsLink}
                                                onChange={(e) => setGoogleMapsLink(e.target.value)}
                                            />
                                        </div>








                                        <div className="col-md-12">
                                            <label> Address <span className="text-danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                value={address}
                                                onChange={(e) => setaddress(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-4">
                                            <label> Country <span className="text-danger">*</span></label>
                                            {
                                                countryArr && (
                                                    <select className="form-control" onChange={(e) => setcountryId(e.target.value)}>
                                                        <option value="">Please Select Country</option>
                                                        {countryArr.map((country) => (
                                                            <option value={country._id} >{country.name}</option>
                                                        ))}
                                                    </select>
                                                )
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <label> State <span className="text-danger">*</span></label>
                                            {
                                                stateArr && (
                                                    <select className="form-control" onChange={(e) => setstateId(e.target.value)}>
                                                        <option value="">Please Select State</option>
                                                        {stateArr.map((state) => (
                                                            <option value={state._id} >{state.name}</option>
                                                        ))}
                                                    </select>
                                                )
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <label> City <span className="text-danger">*</span></label>
                                            {
                                                cityArr && (
                                                    <select className="form-control" onChange={(e) => setcityId(e.target.value)}>
                                                        <option value="">Please Select City</option>
                                                        {cityArr.map((city) => (
                                                            <option value={city._id} >{city.name}</option>
                                                        ))}
                                                    </select>
                                                )
                                            }
                                        </div>
                                        <h4 className="heading yellow">Documents </h4>
                                        {/* <div className="col-md-6">
                                            <label> Profile Photo</label>
                                            <FileUpload onChange={(val) => setprofileImage(val)} />
                                        </div> */}
                                        <div className="col-md-6">
                                            <label> GST Certificate <span className="text-danger">*</span></label>
                                            <FileUpload onFileChange={(value) => setgstCertificate(value)} />
                                        </div>
                                    </div>
                                )}

                            <div className="col-md-6">
                                <button type="button" onClick={() => { handleRegister() }} className="btn btn-custom btn-yellow mt-2">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
