import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getBrandApi } from "../services/brand.service";
import { getNestedCategories } from "../services/Category.service";
import { getCityApi } from "../services/city.service";
import { toastError } from "../utils/toastutill";

function ShopFilter(props) {
  const navigate = useNavigate();

  return (
    <div className="shop-filter">
      <div className="box pb-0">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between align-items-center">
            <h5 className="title">Choose Filters</h5>
            <button className="btn btn-outline btn-outline-danger" type="button" onClick={() => navigate("/Shop")}>
              Clear Filters
            </button>
          </div>
        </div>
      </div>
      <CategoryFilter />
      <LocationFilter />
      {/* <div className="box">
        <h5 className="title">Vendors</h5>
        {brandArr &&
          brandArr.length > 0 &&
          brandArr.map((el, index) => {
            return (
              <li key={el._id}>
                <label>
                  <input type="checkbox" className="form-check-input" />
                  {el?.name}
                </label>
              </li>
            );
          })}
      </div> */}
      <VendorFilter />
      <div className="box">
        <h5 className="title">Price Range </h5>
        <div className="price-range">
          <MinPrize />
          <MaxPrize />
        </div>
      </div>
    </div>
  );
}

const VendorFilter = () => {
  const [brandArr, setBrandArr] = useState([]);

  const getBrands = async () => {
    try {
      const { data: res } = await getBrandApi();
      if (res) {
        setBrandArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getBrands();
  }, []);

  const [selected, setSelected] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const toggleSelected = (id) => {
    let arr = [];
    if (selected.some((el) => el === id)) {
      arr = selected.filter((el) => el !== id);
    } else {
      arr = [...selected, id];
    }
    setSearchParams((searchParams) => {
      let categoryStr = arr.reduce((acc, el, i) => acc + el + (i != arr?.length - 1 ? "," : ""), "");
      if (categoryStr) {
        searchParams.set("vendors", categoryStr);
      } else {
        searchParams.delete("vendors");
      }
      return searchParams;
    });
    setSelected(arr);
  };

  const isChecked = (id) => {
    return selected.some((el) => el === id);
  };

  const location = useLocation();

  useEffect(() => {
    if (searchParams.get("vendors")) {
      let categoryArr = searchParams.get("vendors").split(",");
      setSelected(categoryArr);
    } else {
      setSelected([]);
    }
  }, [searchParams, location.search]);

  return (
    <div className="box">
      <h5 className="title">Brands</h5>
      <ul className="list comm-list">
        {brandArr &&
          brandArr.length > 0 &&
          brandArr.map((el, index) => {
            return (
              <li key={el._id}>
                <label>
                  <input type="checkbox" onChange={(e) => toggleSelected(el?._id)} checked={isChecked(el._id)} className="form-check-input" />
                  {el?.name}
                </label>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const CategoryFilter = () => {
  const [categoryData, setCategoryData] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [selected, setSelected] = useState([]);

  const getCategory = async () => {
    try {
      const { data: res } = await getNestedCategories();
      if (res) {
        console.log(res.data, "filter");
        setCategoryData(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const toggleSelected = (id) => {
    let arr = [];
    if (selected.some((el) => el === id)) {
      arr = selected.filter((el) => el !== id);
    } else {
      arr = [...selected, id];
    }

    console.log("ARR", arr);

    setSearchParams((searchParams) => {
      let categoryStr = arr.reduce((acc, el, i) => acc + el + (i != arr?.length - 1 ? "," : ""), "");
      if (categoryStr) {
        searchParams.set("categories", categoryStr);
      } else {
        searchParams.delete("categories");
      }
      return searchParams;
    });

    setSelected(arr);
  };

  const isChecked = (id) => {
    return selected.some((el) => el === id);
  };

  const location = useLocation();

  useEffect(() => {
    if (searchParams.get("categories")) {
      let categoryArr = searchParams.get("categories").split(",");
      setSelected(categoryArr);
    } else {
      setSelected([]);
    }
  }, [searchParams, location.search]);

  const clearCategoryFilters = () => {
    setSearchParams((searchParams) => {
      searchParams.delete("categories");

      return searchParams;
    });
  };

  return (
    <div className="box">
      <div className="d-flex">
        <div className="flex-1">
          <h5 className="title">Categories</h5>
        </div>
      </div>

      <ul className="list comm-list">
        {/* <li>
          <label>
            <input type="checkbox" className="form-check-input" />
            Select All
          </label>
        </li> */}
        {categoryData &&
          categoryData?.map((el, i) => {
            return (
              <li key={el._id}>
                <label>
                  <input type="checkbox" className="form-check-input" onChange={() => toggleSelected(el._id)} checked={isChecked(el._id)} />
                  {el?.name}
                </label>
                {isChecked(el._id) &&
                  el?.subCategoryArr &&
                  el?.subCategoryArr.map((elx) => (
                    <div className="ms-2" key={elx._id}>
                      <label>
                        <input type="checkbox" className="form-check-input" id={elx._id} onChange={() => toggleSelected(elx._id)} checked={isChecked(elx._id)} />
                        {elx?.name}
                      </label>
                      {isChecked(elx._id) &&
                        elx.subCategoryArr.map((ele) => (
                          <div key={ele._id} className="ms-2">
                            <label>
                              <input type="checkbox" className="form-check-input" onChange={() => toggleSelected(ele._id)} checked={isChecked(ele._id)} />
                              {ele?.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  ))}

                {/* {item?.child.length > 0 && (
                    <Collapse in={item.active}>
                      <ul className="inner-list comm-list">
                        {item.child.map((child, index) => {
                          return (
                            <li>
                              <label key={index}>
                                <input type="checkbox" className="form-check-input" />
                                {child.name}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  )} */}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const LocationFilter = () => {
  const [citiesArr, setCitiesArr] = useState([]);

  const [locationExpand, setLocationExpand] = useState(false);

  const [selected, setSelected] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const toggleSelected = (id) => {
    let arr = [];
    if (selected.some((el) => el === id)) {
      arr = selected.filter((el) => el !== id);
    } else {
      arr = [...selected, id];
    }
    setSearchParams((searchParams) => {
      let categoryStr = arr.reduce((acc, el, i) => acc + el + (i != arr?.length - 1 ? "," : ""), "");
      if (categoryStr) {
        searchParams.set("locations", categoryStr);
      } else {
        searchParams.delete("locations");
      }
      return searchParams;
    });
    setSelected(arr);
  };

  const isChecked = (id) => {
    return selected.some((el) => el === id);
  };

  const location = useLocation();

  useEffect(() => {
    if (searchParams.get("locations")) {
      let categoryArr = searchParams.get("locations").split(",");
      setSelected(categoryArr);
    } else {
      setSelected([]);
    }
  }, [searchParams, location.search]);

  const handleGetCities = async () => {
    try {
      let { data: res } = await getCityApi();
      if (res.data) {
        setCitiesArr(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCities();
  }, []);

  return (
    <div className="box">
      <h5 className="title">Location</h5>
      <ul className="list comm-list">
        {/* <li>
          <div className="d-flex align-items-center gap-2 form mb-2">
            <input type="search" className="form-control flex-1 py-2" placeholder="Enter State" />
            <Link to="#" className="text-danger fw-semibold d-flex align-items-center gap-1 fs-15">
              <MdOutlineMyLocation />
              Near Me
            </Link>
          </div>
        </li> */}
        {citiesArr &&
          citiesArr.length > 0 &&
          citiesArr
            .filter((el, index) => (locationExpand ? true : index <= 3))
            .map((el, index) => {
              return (
                <li key={el._id}>
                  <label>
                    <input type="checkbox" onChange={(e) => toggleSelected(el?._id)} checked={isChecked(el._id)} className="form-check-input" />
                    {el?.name}
                  </label>
                </li>
              );
            })}

        {!locationExpand && (
          <li>
            <h6 className="brown fw-semibold" role="button" onClick={() => setLocationExpand(!locationExpand)} aria-expanded={locationExpand}>
              Expand all
            </h6>
          </li>
        )}
        {/* {citiesArr?.map((item, i) => {
          return (
            <Collapse in={locationExpand}>
              <li key={i}>
                <label>
                  <input type="checkbox" className="form-check-input" />
                  {item?.name}
                </label>
              </li>
            </Collapse>
          );
        })} */}
      </ul>
    </div>
  );
};

const MinPrize = () => {
  const [minPrice, setMinPrice] = useState(0);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const minPrizeTooltip = (props) => <Tooltip {...props}>{minPrice}</Tooltip>;

  useEffect(() => {
    if (searchParams.get("minPrice")) {
      let minvall = searchParams.get("minPrice");
      setMinPrice(minvall);
    } else {
      setMinPrice(0);
    }
  }, [searchParams, location.search]);

  const setValue = (min = 0) => {
    setSearchParams((searchParams) => {
      if (min != 0) {
        searchParams.set("minPrice", min);
      } else {
        searchParams.delete("minPrice");
      }
      return searchParams;
    });
  };

  return (
    <div>
      <label className="fs-15 fw-semibold line-height-normal">Min</label>
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={minPrizeTooltip}>
        <input type="range" className="form-range" step="100" min="0" max="500" onChange={(e) => setValue(e.target.value)} value={minPrice} />
      </OverlayTrigger>
    </div>
  );
};

const MaxPrize = () => {
  const [maxPrice, setMaxPrice] = useState(0);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const maxPrizeTooltip = (props) => <Tooltip {...props}>{maxPrice}</Tooltip>;

  useEffect(() => {
    if (searchParams.get("maxPrice")) {
      let minvall = searchParams.get("maxPrice");
      setMaxPrice(minvall);
    } else {
      setMaxPrice(0);
    }
  }, [searchParams, location.search]);

  const setValue = (min = 0) => {
    setSearchParams((searchParams) => {
      if (min != 0) {
        searchParams.set("maxPrice", min);
      } else {
        searchParams.delete("maxPrice");
      }
      return searchParams;
    });
  };

  return (
    <div>
      <label className="fs-15 fw-semibold line-height-normal">Max</label>
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={maxPrizeTooltip}>
        <input type="range" className="form-range" step="100" min="0" max="55000" onChange={(e) => setValue(e.target.value)} value={maxPrice} />
      </OverlayTrigger>
    </div>
  );
};

export default ShopFilter;
