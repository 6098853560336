import axios from "axios";

import { url } from "./url.service";

const serverUrl = url + "/leads";


export const createLead = (obj) => {
    return axios.post(`${serverUrl}/`, obj);
};


export const getLeadsBycreatedById = (id) => {
    return axios.get(`${serverUrl}/getLeadsBycreatedById/${id}`);
};

export const getLeadsById = (id) => {
    return axios.get(`${serverUrl}/getbyId/${id}`);
};
